<template>
    <div>
        <van-nav-bar class="learning-title" fixed left-arrow>
            <template #left>
                <van-button color="#ffffff" icon="arrow-down" @click="onBack">我的课程</van-button>
            </template>
            <!-- <template #right>
                <van-grid>
                    <van-grid-item icon="chat-o" text="听课帮助"/>
                </van-grid>
            </template> -->
        </van-nav-bar>

        <van-tabs sticky class="courseList" color="#1989fa" @click="clickTab">
            <van-tab name="0" title="当期课程">
                <div v-if="courseData.length>0">
                    <div class="courseCell"
                         v-for="(item,index) in courseData"
                         :key="index">
                        <van-tag round color="#1989fa" text-color="#ffffff"><font>{{index+1}}</font>{{item.cate_name}}</van-tag>
                        <div v-for="(item2,index2) in item.data" :key="index2">
                            <van-cell center is-link v-if="item2.courseware > 0" :data-id="item2.id" :data-courseware="item2.coursewareId" @click="getCourseWareList(item2.id,item2.coursewareId)">
                                <template #icon>
                                    <van-icon name="circle"/>
                                </template>
                                <template #title>
                                    <div class="van-cell__title">
                                        {{item2.title}}
                                    </div>
                                </template>
                                <template #label>
                                    <div class="van-cell__label">共{{item2.courseware}}节课，{{item2.duration}}小时</div>
                                </template>
                            </van-cell>
                            <van-cell center is-link v-else>
                                <template #icon>
                                    <van-icon name="circle"/>
                                </template>
                                <template #title>
                                    <div class="van-cell__title">
                                        {{item2.title}}
                                    </div>
                                </template>
                                <template #label>
                                    <div class="van-cell__title">
                                        课程暂未更新
                                    </div>
                                </template>
                            </van-cell>
                        </div>
                    </div>
                </div>
                <!-- 无课程的时候显示 -->
                <van-empty
                        v-else
                  description="当期课程暂未更新"
                  image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                  image-size="24rem">
                  <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>
                </van-empty>
            </van-tab>
            <van-tab name="1" title="赠送课程"  v-if="courseFreeData.length>0">
                <div class="courseCell" v-for="(item,index) in courseFreeData" :key="index" v-if="courseFreeData.length > 0">
                    <van-tag round color="#1989fa" text-color="#ffffff"><font>{{index+1}}</font>{{item.cate_name}}</van-tag>
                    <div v-for="(item2,index2) in item.data" :key="index2">
                        <van-cell center is-link v-if="item2.courseware > 0" :data-id="item2.id" :data-courseware="item2.coursewareId" @click="getCourseWareList(item2.id,item2.coursewareId)">
                            <template #icon>
                                <van-icon name="circle"/>
                            </template>
                            <template #title>
                                <div class="van-cell__title">
                                    {{item2.title}}
                                </div>
                            </template>
                            <template #label>
                                <div class="van-cell__label">共{{item2.courseware}}节课，{{item2.duration}}小时</div>
                            </template>
                        </van-cell>
                        <van-cell center is-link v-else>
                            <template #icon>
                                <van-icon name="circle"/>
                            </template>
                            <template #title>
                                <div class="van-cell__title">
                                    {{item2.title}}
                                </div>
                            </template>
                            <template #label>
                                <div class="van-cell__title">
                                    课程暂未更新
                                </div>
                            </template>
                        </van-cell>
                    </div>
                </div>
                <!-- 无课程的时候显示 -->
                <van-empty
                    v-else
                    description="当期课程暂未更新"
                    image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                    image-size="24rem">
                  <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>
                </van-empty>
            </van-tab>

        </van-tabs>

        <van-cell center class="lastCell" @click="lastStudyDetail" v-if="lastStudy">
            <template #icon>
                <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/last_learning1.png"/>
            </template>
            <template #title>
                <div class="van-cell__title">
                    {{lastStudy.title}}
                </div>
            </template>
            <template #label>
                <van-tag color="#07c160">{{lastStudy.video_title}}</van-tag>
            </template>
        </van-cell>


    </div>
</template>

<script>
    import {courseList, courseFreeList,courseAllList} from "@/request/api";

    // let commodityId;
    export default {
        beforeRouteEnter(to, from, next) {
            let that = this;
            // console.log(to);
            // console.log(from);
            // console.log(next);
            // if (!to.params || !to.params.id) {
            //     next({
            //         path: from.name,
            //         query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
            //     })
            // }
            next();
        },
        metaInfo() {
            return {
                title: this.title+'-学习', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                active: 1,
                item: '',
                courseData: [],
                courseFreeData: [],
                lastStudy: [],
                courseShow: true,
                commodityId: '',
                coursewareId: ''
            }
        },
        methods: {
            course() {
                let that = this;
                let commodityId = that.commodityId;
                let type = that.tab_id;
                courseAllList({id: commodityId,type:1})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.courseData = res.data.data.data;
                            that.courseFreeData = res.data.data.dataFree;
                            that.lastStudy = res.data.data.lastStudy;
                            that.courseShow = true;
                            that.active = res.data.data.active;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            courseFree() {
                let that = this;
                let commodityId = that.commodityId;
                let type = that.tab_id;
                courseList({id: commodityId,type:2})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.courseFreeData = res.data.data.data;
                            that.lastStudy = res.data.data.lastStudy;
                            that.courseShow = true;
                            that.active = res.data.data.active;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            clickTab(name,title) {
                this.tab_id = name;
                // if(this.tab_id == 0) {
                //     this.course();
                // } else {
                //     this.courseFree();
                // }
            },
            getCourseWareList(id,coursewareId) {
                localStorage.setItem('learningParams',JSON.stringify({commodityId:this.commodityId}))
                let commodityId = this.commodityId;
                this.$router.push({
                    name:'MyCourseDetail',
                    params:{
                        id,
                        commodityId,
                        coursewareId
                    }
                })
            },
            lastStudyDetail() {
                const {id,commodityId,coursewareId} = this.lastStudy;
                console.log(id);
                console.log(commodityId);
                console.log(coursewareId);
                this.$router.push({
                    name:'MyCourseDetail',
                    params:{
                        id,
                        commodityId,
                        coursewareId
                    }
                })
            },
            onBack() {
                // this.$router.go(-1)
                // this.$router.back(-1)
                // history.back(-1);
                this.$router.push('/learning');
            },
        },
        created() {
            if(this.$route.params.id) {
                this.commodityId = this.$route.params.id
            } else {
                let params = localStorage.getItem('learningParams');
                this.commodityId = JSON.parse(params).commodityId;
            }
            this.course();
            this.title = this.getBranchCompany()
        }

    }
</script>

<style lang="less">
    .learning-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 2.2rem !important;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-right: 2rem;
        font-weight: 600;
        color: #333333;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        font-size: 2.8rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
        font-size: 1.4rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right {
        padding: 0 0.5rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
        width: 6rem !important;
    }

    .courseList {
        padding: 0rem 0 8rem 0;
    }

    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 0;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }
    .courseList .van-sticky{
        padding-top: 6rem ;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }

    .courseCell {
        margin: 1rem;
        border-radius: 1rem;
        padding: 1.5rem;
        background-color: #ffffff;
    }

    .courseCell .van-tag {
        padding: 0.4rem 1rem 0.4rem 0.5rem !important;
        font-size: 1.6rem !important;
    }

    .courseCell .van-tag font {
        color: #1989fa;
        font-size: 1.6rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.3rem;
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .courseCell .van-cell {
        padding: 1rem 0;
    }

    .courseCell .van-cell .van-icon {
        margin-right: 0.8rem;
        font-size: 1.8rem;
        color: #646566;
    }

    .courseCell .van-cell .van-cell__title {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .courseCell .van-cell .van-cell__label {
        font-size: 1.6rem;
        line-height: 3rem;
    }

    .lastCell {
        padding: 1rem 1.5rem;
        background-color: #1989fa;
        position: fixed;
        bottom: 0;
    }

    .lastCell .van-icon {
        font-size: 6rem;
        margin-right: 1rem;
    }

    .lastCell .van-cell__title {
        color: #ffffff;
        line-height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lastCell .van-cell__label {
        line-height: 3rem;
    }

    .lastCell .van-cell__label .van-tag {
        font-size: 1.6rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0.3rem;
    }

</style>